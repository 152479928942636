<template>
    <a-card>
        <a-page-header
            title="巨量创意组件模板管理"
        />

        <a-form-model
            ref="form"
            :model="form"
            v-bind='layout'
        >
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="品牌">
                        <a-select
                            mode="multiple"
                            v-model="form.principalIdList"
                            placeholder="请选择品牌"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                            @change="onChangePrincipal"
                        >
                            <a-select-option
                                v-for="item in principalList"
                                :key="item.id"
                            >
                                {{ item.principal_name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="集客场景">
                        <a-select
                            mode="multiple"
                            v-model="form.customerSceneList"
                            placeholder="请选择"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option
                                v-for="item in customerSceneList"
                                :key="item.code"
                            >
                                {{ item.desc }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="8">
                    <a-form-model-item label="车系">
                        <a-select
                            mode="multiple"
                            v-model="form.carSeriesIdList"
                            placeholder="请选择车系"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option
                                v-for="item in seriesList"
                                :key="item.id"
                            >
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col class="flex-end">
                    <a-space>
                        <a-button @click="handleReset">重置</a-button>
                        <a-button type="primary" @click="handleSearch">查询</a-button>
                        <a-button type="primary" @click="jumpDetailPage(0)">新建模板</a-button>
                    </a-space>
                </a-col>
            </a-row>
        </a-form-model>

        <div class="flex-end mt-20">新增/修改模板每30分钟更新一次给对应品牌的广告主</div>

        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :rowKey="record => record.id"
            :pagination='false'
            class="mt-20"
            @change="handleTableChange"
        >
            <div
                slot="imageUrl"
                slot-scope="text, record"
            >
                <img :src="record.imageUrl" class="img">
            </div>
            <div
                slot="operation"
                slot-scope="text, record"
            >
                <base-button
                    :type="'link'"
                    :title="'修改'"
                    @onClickBtn="jumpDetailPage(record.id)"
                ></base-button>
                <base-button
                    :type="'link'"
                    :title="'复制'"
                    @onClickBtn="jumpDetailPage(record.id, 'copy')"
                ></base-button>
            </div>
        </a-table>

        <base-pagination
            :currentPage="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
        />
    </a-card>
</template>

<script>
export default {
    data() {
        return {
            layout: {
                labelCol: { span: 8 },
                wrapperCol: { span: 16 }
            },
            form: {
                principalIdList: [],
                customerSceneList: [],
                carSeriesIdList: []
            },
            loading: false,
            columns: [
                {
                    align: 'center',
                    title: '同步率',
                    dataIndex: 'syncRate',
                    sorter: true,
                    customRender: (text, row) => {
                        return (row.syncRate ?? '') !== '' ? `${(row.syncRate).toFixed(2)}%` : '-'
                    }
                },
                {
                    align: 'center',
                    title: '品牌',
                    dataIndex: 'principalName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '车系',
                    dataIndex: 'carSeriesName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '集客场景',
                    dataIndex: 'customerSceneDesc',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '组件名称',
                    dataIndex: 'componentName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '组件类型',
                    dataIndex: 'componentType',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '组件适用范围',
                    customRender: (text, row) => {
                        return row.componentScope == 1 ? '车系' : row.componentScope == 2 ? '品牌' : '-'
                    }
                },
                {
                    align: 'center',
                    title: '卡片主图',
                    scopedSlots: { customRender: 'imageUrl' },
                },
                {
                    align: 'center',
                    title: '卡片标题',
                    dataIndex: 'title',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '行动号召',
                    dataIndex: 'buttonText',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '操作',
                    dataIndex: 'operation',
                    scopedSlots: { customRender: 'operation' },
                },
                {
                    align: 'center',
                    title: '最新更新时间',
                    dataIndex: 'mtime',
                    customRender(text) {
                        return text || '-'
                    }
                },
            ],
            dataList: [],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0
            },
            principalList: [],
            seriesList: [],
            customerSceneList: [],
        }
    },
    created() {
        this.getPrincipalList()
        this.getPrincipalSeriesList()
        this.getCustomerScene()
        this.getDataList()
    },
    methods: {
        getPrincipalList() {
            this.$api.base_api.getPrincipalList().then(res => {
                if(res.code == 200) {
                    this.principalList = res.data
                } else {
                    this.principalList = []
                    console.error(`获取主体失败，${res}`)
                }
            })
        },
        onChangePrincipal(id) {
            this.getPrincipalSeriesList(id)
        },
        getPrincipalSeriesList(id) {
            let params = {
                principalIds: id,
                status: [1, 4]
            }
            this.$api.base_api.getPrincipalSeriesList(params).then(res => {
                if(res.code == 200) {
                    this.seriesList = res.data
                } else {
                    this.seriesList = []
                    console.error(`获取车系失败，${res}`)
                }
            })
        },
        getCustomerScene() {
            this.$api.core.materialManage.getCustomerScene().then(res => {
                if(res.code == 200) {
                    this.customerSceneList = res.data
                } else {
                    console.error(`获取集客场景失败，${res}`)
                }
            })
        },
        handleSearch() {
            this.pagination.current = 1
            this.getDataList()
        },
        handleReset() {
            this.form = {
                principalIdList: [],
                customerSceneList: [],
                carSeriesIdList: []
            }
            this.pagination.current = 1
            this.pagination.pageSize = 10
            this.getDataList()
            this.seriesList = []
            this.getPrincipalSeriesList()
        },
        handleTableChange(pagination, filters, sorter) {
            this.getDataList({
                syncRateOrder: sorter.order == 'ascend' ? 'asc' : sorter.order == 'descend' ? 'desc' : '',
            });
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        getDataList(data = {}) {
            this.loading = true
            const _form = this.form
            let params = {
                principalIdList: _form.principalIdList.toString(),
                customerSceneList: _form.customerSceneList.toString(),
                carSeriesIdList: _form.carSeriesIdList.toString(),
                ...data,
                page: this.pagination.current,
                size: this.pagination.pageSize,
            }
            this.$api.core.materialManage.getCreativeTemplateList(params).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.dataList = res.data.list || []
                    this.pagination.total = res.data.total || 0
                } else {
                    this.dataList = []
                    this.pagination.total = 0
                    console.error(`获取列表失败${res}`)
                }
            })
        },
        jumpDetailPage(id, type) {
            this.$router.push({
                path: `/creativeTemplateManage/detail`,
                query: {
                    id,
                    type
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}

.mt-20 {
    margin-top: 20px;
}
.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.link {
    text-decoration: underline;
}
.img {
    width: 60px;
}
</style>
